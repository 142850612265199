export default {
  namespaced: true,
  state: {
    users: {
      fetchingUserList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_USER_LIST(state, newVal) {
      console.log('UPDATE_FETCHING_USER_LIST')
      state.users.fetchingUserList = newVal
      console.log(state.users.fetchingUserList)
    },
  },
  actions: {},
}
