export default {
  namespaced: true,
  state: {
    cameraDownloads: {
      fetchingCameraImageDownloadsList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_CAMERAS_MANAGEMENT_LIST(state, newVal) {
      state.cameraDownloads.fetchingCameraImageDownloadsList = newVal
    },
  },
  actions: {},
}
