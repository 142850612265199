export default {
  namespaced: true,
  state: {
    firmware: {
      fetchingFirmwareList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_FIRMWARE_LIST(state, newVal) {
      state.firmware.fetchingFirmwareList = newVal
      console.log(state.firmware.fetchingFirmwareList)
    },
  },
  actions: {},
}
