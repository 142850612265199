export default {
  namespaced: true,
  state: {
    logs: {
      fetchingList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_LIST(state, newVal) {
      state.logs.fetchingList = newVal
      console.log(state.logs.fetchingList)
    },
  },
  actions: {},
}
