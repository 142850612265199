export default {
  namespaced: true,
  state: {
    reports: {
      fetchingReportList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_REPORT_LIST(state, newVal) {
      console.log('UPDATE_FETCHING_REPORT_LIST')
      state.reports.fetchingReportList = newVal
      console.log(state.reports.fetchingReportList)
    },
  },
  actions: {},
}
