export default {
  namespaced: true,
  state: {
    userSettings: {
      cameraListClientFilter: null,
      cameraListViewTypeFilter: null,
      cameraListSearchQuery: null,
      cameraListSiteFilter: null,
      cameraListStatusFilter: null,
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}
