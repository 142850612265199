export default {
  namespaced: true,
  state: {
    camerasManagement: {
      fetchingCamerasManagementList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_CAMERAS_MANAGEMENT_LIST(state, newVal) {
      state.camerasManagement.fetchingCamerasManagementList = newVal
    },
  },
  actions: {},
}
