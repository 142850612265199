export default {
  namespaced: true,
  state: {
    abilitiestemplates: {
      fetchingAbilitiesTemplatesList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_ABILITIES_TEMPLATES_LIST(state, newVal) {
      state.abilitiestemplates.fetchingAbilitiesTemplatesList = newVal
    },
  },
  actions: {},
}
