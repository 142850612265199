import axios from 'axios'
import { BCol, BRow, BButton } from 'bootstrap-vue'

// eslint-disable-next-line import/prefer-default-export
export const refreshPageMixin = {
  components: {
    BCol,
    BRow,
    BButton,
  },
  data() {
    return {
      currentVersion: '{{POST_BUILD_ENTERS_VERSION_HERE}}',
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      hashChanged: false,
      versionChanged: false,
      newHash: '',
      newVersion: 0,
    }
  },
  methods: {
    initVersionCheck(url = 'version.json', frequency = 1000 * 60 * 2) {
      console.log('initVersionCheck')
      setInterval(() => {
        this.checkVersion(url)
      }, frequency)
    },
    async checkVersion(url) {
      console.log('checkVersion')
      try {
        const fileResponse = await axios.create({
          headers: {
            'Content-type': 'application/json',
          },
        }).get(`/${url}?t=${new Date().getTime()}`)

        this.newVersion = fileResponse.data.version
        console.log(fileResponse)
        console.log(this.newVersion)

        this.versionChanged = this.hasVersionChanged(this.currentVersion, this.newVersion)

        if (this.versionChanged) {
          this.showReloadModal()
        }
      } catch (error) {
        this.loading = false
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message
        }
      }
    },
    hasHashChanged(currentHash, newHash) {
      console.log('hasHashChanged')
      console.log(this.currentHash)
      if (!currentHash || currentHash === this.currentHash) {
        return true
      }

      return currentHash !== newHash
    },
    hasVersionChanged(currentVersion, newVersion) {
      return currentVersion !== newVersion
    },
    reloadApp() {
      this.currentHash = this.newHash
      window.location.reload()
    },
    showReloadModal() {
      console.log('showReloadModal')
      const h = this.$createElement
      this.picToastcount += 1
      const theId = `pic-toast-${this.picToastcount}`

      // Create the custom message
      const vNodesMsg = h(
        'div', [
          h('b-row',
            [
              h('b-col', { class: ['col-12'] },
                [
                  h('small', 'An upate is available. Please CLEAR YOUR BROWSER CACHE and refresh as soon as possible to avoid possible errors.'),
                ]),
            ]),
          h('b-row', { class: ['col-12', 'text-right', 'mt-1'] },
            [
              h('b-button', {
                props: {
                  variant: 'primary',
                },
                on: {
                  click: () => {
                    this.reloadApp()
                  },
                },
              }, 'Refresh'),
            ]),
        ],
      )
      // Create the title
      const vNodesTitle = h(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
        [
          h('strong', { class: 'mr-2' }, 'Update Available'),
        ],
      )
      // Create the toast
      this.$bvToast.toast([vNodesMsg], {
        id: theId,
        title: [vNodesTitle],
        // noCloseButton: true,
        solid: true,
        // variant: 'primary',
        noAutoHide: true,
      })
    },
  },
}
