export default {
  namespaced: true,
  state: {
    cameraconsole: {
      fetchingCameraConsoleList: true,
      fetchingTimelapseList: true,
      disableRefresh: false,
    },
  },
  getters: {},
  mutations: {
    UPDATE_CAMERAS_MANAGEMENT_LIST(state, newVal) {
      state.cameraconsole.fetchingCameraConsoleList = newVal
    },
    UPDATE_CAMERA_TIMELAPSE_LIST(state, newVal) {
      state.cameraconsole.fetchingTimelapseList = newVal
    },
  },
  actions: {},
}
